




















































// Core
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Types
import { ProjectsActions } from '@/store/projects/types'

// Interfaces
import { ElForm } from 'element-ui/types/form'
import { Project } from '@/store/projects/interfaces'

// Modules
const NSProjects = namespace('projectsModule')

@Component({
  name: 'DialogAddProject',
})
export default class DialogAddProjectComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @NSProjects.Action(ProjectsActions.A_CREATE_PROJECT)
  private createProject!: (project: Project) => Promise<Project>

  private isExpectation: boolean = false

  private form: Project = {
    name: '',
    address: '',
    description: '',
    startDate: '',
    endDate: '',
  }

  protected rules = {
    name: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    address: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  private handleCreateProject() {
    const form = this.$refs.form as ElForm

    form.validate(valid => {
      if (valid) {
        this.isExpectation = true

        this.createProject(this.form)
          .then(() => {
            this._visible = false
            this.$successNotify()
          })
          .catch(error => {
            this.$errorNotify(error?.response?.data?.message)
          })
          .finally(() => {
            this.isExpectation = false
          })
      }
    })
  }
}
